import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.less';
// import registerServiceWorker from './registerServiceWorker';
import { App } from './theme/components/App';

ReactDOM.render(
  <App />,
  document.getElementById('root') as HTMLElement
);
// registerServiceWorker();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations: ServiceWorkerRegistration[]) => {
    for (const registration of registrations) {
      // Unregister a specific service worker if needed
      registration.unregister();
    }
  });
  window.location.href = "https://www.moose-offroad.com"; 
}
window.location.href = "https://www.moose-offroad.com"; 